/**
 * returns absolute url made from given relative one
 */
export function getAbsoluteUrl(relativeUrl) {
  if (!window.location.origin) {
    throw Error('No base url.');
  }
  return `${window.location.origin}${relativeUrl}`;
}

/**
 * strips jwt query param from url and returns it.
 * If not found returns empty string.
 */
export function stripJwtFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const jwt = urlParams.get('jwt') ?? '';
  if (jwt) {
    setTimeout(() => {
      window.history.replaceState(null, '', window.location.pathname);
    });
  }
  return jwt;
}
