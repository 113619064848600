import { useSetRecoilState } from 'recoil';
import { useCallback } from 'react';

import { SnackbarAtom } from 'atoms/snackbar';

/**
 * Hook to use snackbar notifications
 * @param { int|null } duration - time to show snackbar. If null - shown until close
 * @returns {{ errorNotification: function, successNotification: function }}
 */
export function useNotification(duration = 7000) {
  const setSnackbar = useSetRecoilState(SnackbarAtom);
  const errorNotification = useCallback(
    (message, translate = true) =>
      setSnackbar({ message, severity: 'error', duration, translate }),
    [setSnackbar, duration],
  );
  const successNotification = useCallback(
    (message, translate = true) =>
      setSnackbar({ message, severity: 'success', duration, translate }),
    [setSnackbar, duration],
  );

  return { errorNotification, successNotification };
}
