import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { SnackbarAtom } from 'atoms/snackbar';

import { useStyles } from './styles';

const SNACKBAR_POSITION = {
  vertical: 'top',
  horizontal: 'center',
};

/**
 * shows Snackbar which is set to SnackbarAtom
 */
function SnackbarManager({ autoHideDuration }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useRecoilState(SnackbarAtom);
  const handleClose = useCallback(
    (_, reason) => reason !== 'clickaway' && setSnackbar(null),
    [setSnackbar],
  );

  if (!snackbar) {
    return null;
  }

  const duration = {}.hasOwnProperty.call(snackbar, 'duration')
    ? snackbar.duration
    : autoHideDuration;
  const message = snackbar.translate ? t(snackbar.message) : snackbar.message;

  return (
    <Snackbar
      open
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={SNACKBAR_POSITION}
      className={classes.snackbar}
    >
      <Alert severity={snackbar.severity || 'info'} onClose={handleClose}>
        {message || ''}
      </Alert>
    </Snackbar>
  );
}

SnackbarManager.propTypes = {
  autoHideDuration: PropTypes.number,
};

SnackbarManager.defaultProps = {
  autoHideDuration: 4000,
};

export default memo(SnackbarManager);
