// OLD, simplified API related
const JWT_ACCESS_TOKEN_KEY = 'accessJwtToken';
const JWT_REFRESH_TOKEN_KEY = 'refreshJwtToken';
// NEW
const JWT_TOKEN_KEY = 'jwtToken';

/**
 * Class to incapsulate working with storage logic
 * It's used as a singleton to not call exact implementation (like `localStorage`) directly
 * if it'd be needed to change storage implementation, it should be changed here
 */
class Storage {
  set(key, value) {
    localStorage.setItem(key, value);
  }

  get(key) {
    return localStorage.getItem(key);
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  // OLD, simplified API related
  setAccessToken(jwt) {
    return this.set(JWT_ACCESS_TOKEN_KEY, jwt);
  }

  setRefreshToken(jwt) {
    return this.set(JWT_REFRESH_TOKEN_KEY, jwt);
  }

  getAccessToken() {
    return this.get(JWT_ACCESS_TOKEN_KEY) || '';
  }

  getRefreshToken() {
    return this.get(JWT_REFRESH_TOKEN_KEY) || '';
  }

  resetUser() {
    this.set(JWT_ACCESS_TOKEN_KEY, '');
    this.set(JWT_REFRESH_TOKEN_KEY, '');
  }

  // NEW
  setJwt(jwt) {
    return this.set(JWT_TOKEN_KEY, jwt);
  }

  getJwt() {
    return this.get(JWT_TOKEN_KEY) || '';
  }
}

export const storage = new Storage();
