import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  base: {
    color: ({ color, opacity }) => alpha(color, opacity),
  },
  center: {
    zIndex: 1,
    position: 'fixed',
    left: '50%',
    top: '50%',
    display: 'block',
  },
  justifyCenter: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
});
