import request from 'utils/request';
import { config } from 'config';

/**
 * Requests current user
 * @returns { Promise }
 */
export const getCurrentUser = () => {
  const apiUrl = config.get('apiUrl');
  return request.get(`${apiUrl}/users/me/`, {
    authLogout: false,
    data: null,
  });
};
