import React, { memo, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Box from '@mui/material/Box';

import Routes from 'components/app/Routes';
import { useNotification } from 'hooks/useNotifications';
import { config } from 'config';
import Loader from 'components/common/Loader';
import { getCurrentUser } from 'requests/user';
import { EMPTY_OBJECT } from 'constants/common';
import { UserAtom } from 'atoms/user';
import { stripJwtFromUrl } from 'utils/url';
import { storage } from 'utils/storage';

function App() {
  const { errorNotification } = useNotification();
  const [user, setUser] = useRecoilState(UserAtom);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    const jwtFromUrl = stripJwtFromUrl();
    if (jwtFromUrl) {
      storage.setJwt(jwtFromUrl);
    }
    fetch('/config.json')
      .then(response => response.json())
      .then(configData => {
        if (!Object.keys(configData).length) {
          throw new Error('Configuration error.');
        }
        return configData;
      })
      .then(config.load.bind(config))
      .then(() => getCurrentUser())
      .then(setUser)
      .catch(error => {
        if (error.status === 401) {
          setUser(EMPTY_OBJECT);
          return;
        }
        setFailed(true);
        const message = !config.loaded
          ? 'errors.configError'
          : 'errors.serverError';
        errorNotification(message);
      });
  }, [errorNotification, setUser, setFailed]);

  return (
    <Box height="100%">
      {user && !failed ? (
        <BrowserRouter>
          <Routes user={user} />
        </BrowserRouter>
      ) : (
        !failed && <Loader center size={80} />
      )}
    </Box>
  );
}

export default memo(App);
