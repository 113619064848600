import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

import { palette } from 'common/theme';

import { useStyles } from './styles';

/**
 * Common loader component
 * @param { Boolean } center - place loader in center vertically and horizontally
 * @param { Boolean } justifyCenter - place loader in center horizontally
 * @param { Number } size - size in px
 * @param { string } color - color
 * @param { Number } opacity - fill opacity
 * @param { Object } props - rest props
 * @see https://material-ui.com/ru/api/circular-progress
 */
function Loader({ center, size, color, opacity, justifyCenter, ...props }) {
  const classes = useStyles({ color, opacity });
  const wrapperClass = classNames(classes.base, {
    [classes.justifyCenter]: justifyCenter,
    [classes.center]: center,
  });

  return (
    <div className={wrapperClass}>
      <CircularProgress {...props} color="primary" size={size} />
    </div>
  );
}

Loader.propTypes = {
  justifyCenter: PropTypes.bool,
  center: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  opacity: PropTypes.number,
};

Loader.defaultProps = {
  justifyCenter: false,
  center: false,
  color: palette.common.black,
  opacity: 0.5,
  size: 50,
};

export default React.memo(Loader);
