import React, { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { useToggle } from 'hooks/useToggle';
import { NAVBAR_Z_INDEX } from 'common/theme';

// eslint-disable-next-line react/prop-types
function UserDataRow({ label, value }) {
  return (
    <Box display="flex" lineHeight={1.8}>
      <Box fontWeight={600} width="80px">
        {label}:
      </Box>
      &nbsp;
      <Box maxWidth={250} style={{ wordBreak: 'break-word' }}>
        {value}
      </Box>
    </Box>
  );
}

function UserPopper({ user, logout }) {
  const { t } = useTranslation('translation');
  const anchorRef = useRef(null);
  const [open, toggle] = useToggle(false);

  return (
    <Box>
      <Box
        style={{ cursor: 'pointer' }}
        lineHeight={1}
        height={28}
        fontSize={28}
        onClick={toggle}
        ref={anchorRef}
        color="white"
      >
        <PersonOutlineRoundedIcon color="white" fontSize="inherit" />
      </Box>
      <Popper
        id="user-popper"
        open={open}
        anchorEl={anchorRef.current}
        style={{ zIndex: NAVBAR_Z_INDEX }}
      >
        <Box
          mt={3}
          p="12px"
          pt="42px"
          pr="20px"
          borderRadius="8px"
          boxShadow={10}
          position="relative"
          bgcolor="neutral.n900"
        >
          <Box
            onClick={toggle}
            style={{ cursor: 'pointer' }}
            position="absolute"
            right="4px"
            top="4px"
          >
            <CloseIcon fontSize="small" />
          </Box>
          <UserDataRow label={t('profile.email')} value={user.email} />
          <Divider />
          <UserDataRow
            label={t('profile.roles')}
            value={user?.userRoles?.join(', ')}
          />
          <Divider />
          <UserDataRow
            label={t('profile.company')}
            value={user.customer?.name || '-'}
          />
          <Box mb="10px" />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={logout}
          >
            {t('common.buttons.logout')}
          </Button>
        </Box>
      </Popper>
    </Box>
  );
}

UserPopper.propTypes = {
  logout: PropTypes.func,
  user: PropTypes.shape({
    userId: PropTypes.number,
    email: PropTypes.string,
    customer: PropTypes.shape({
      name: PropTypes.string,
    }),
    userRoles: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default memo(UserPopper);
