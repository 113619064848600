import request from 'utils/request';
import { config } from 'config';

/**
 * isRetry is true to not trigger auth check, refresh is part of "retry" flow
 * @return {Promise}
 */
export const refreshAccessToken = () => {
  const ssoUrl = config.get('ssoUrl');
  return request.get(`${ssoUrl}/auth/refresh`, { isRetry: true });
};
