export function EMPTY_FUNCTION() {
  return null;
}

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

export const STATUS_NOT_REQUESTED = 'STATUS_NOT_REQUESTED';
export const STATUS_LOADING = 'STATUS_LOADING';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';
export const STATUS_WARNING = 'STATUS_WARNING';
export const STATUS_ERROR = 'STATUS_ERROR';

export const NEW_ID = -1;
