import axios from 'axios';

import { DEFAULT_SERVER_ERROR } from 'constants/errors';
import { storage } from 'utils/storage';
import { refreshAccessToken } from 'requests/auth';
import { logout } from 'utils/auth';

/**
 * basic axios configuration
 * @return { Object } axios instance
 */
const requestInstance = axios.create({
  withCredentials: true,
  // true if request is send second time
  isRetry: false,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
});

/**
 * Processes failed request if reason is invalid token
 * tries to refresh access token
 * when accessToken received - retries original request
 * @return Promise
 */
function processInvalidToken(requestConfig) {
  // eslint-disable-next-line no-param-reassign
  requestConfig.isRetry = true;
  return refreshAccessToken()
    .then(({ accessToken }) => {
      storage.setJwt(accessToken);
      // eslint-disable-next-line no-param-reassign
      requestConfig.headers.authorization = `Bearer ${accessToken}`;
    })
    .then(() => requestInstance(requestConfig));
}

function errorHandler(errorData) {
  return new Promise((resolve, reject) => {
    const { response = {}, config } = errorData;
    const { status = null } = response;
    if (status === 401 && !config.isRetry) {
      processInvalidToken(config)
        .then(resolve)
        .catch(error => {
          reject(error);
          if (error?.status === 401) logout();
        });
      return;
    }
    const message = response.data?.error || DEFAULT_SERVER_ERROR;
    const errorObject = { status, message };
    reject(errorObject);
  });
}

/**
 * wraps request in a promise which on fall resolves with given fallback value
 * logs error to console
 * @returns {Promise<unknown>}
 */
export function withSilentError(requestCall, fallbackValue = {}) {
  return new Promise(resolve => {
    requestCall
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.error(error);
        resolve(fallbackValue);
      });
  });
}

/**
 * adds jwt to each request
 */
function authInterceptor(config) {
  const jwt = storage.getJwt();
  // eslint-disable-next-line no-param-reassign
  config.headers.authorization = `Bearer ${jwt}`;
  return config;
}
requestInstance.interceptors.request.use(authInterceptor);

/**
 * Catches `203` status responses to do redirect (30x redirects are not handled properly in ajax)
 */
requestInstance.interceptors.response.use(({ data, status }) => {
  if (status === 203 && data.url) {
    window.location = data.url;
    // never resolved promise trick to not propagate to handlers
    return new Promise(() => {});
  }
  return data;
}, errorHandler);

export default requestInstance;
