import React, { memo, lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import CommonLayout from 'components/layouts/CommonLayout';
import { ROUTES } from 'constants/routes';
import { TOOLS_LINKS } from 'constants/tools';
import DotsLoader from 'components/common/DotsLoader';

const Dashboard = lazy(() => import('components/pages/Dashboard'));
const Media = lazy(() => import('components/tools/Media'));
const Campaigns = lazy(() => import('components/tools/Campaigns'));
const CampaignCreate = lazy(() => import('components/tools/CampaignCreate'));
const CampaignEdit = lazy(() => import('components/tools/CampaignEdit'));
const Upload = lazy(() => import('components/tools/Upload'));
const SlidesBuilder = lazy(() => import('components/tools/SlidesBuilder'));
const NotFound = lazy(() => import('components/pages/NotFound'));

const LOADING_FALLBACK = <DotsLoader backdrop />;

/**
 * Routes Component. Manages all application routes
 * Takes isAuthorized prop to manage public/private routes
 * @param { Boolean } isAuthorized
 */
function Routes({ user }) {
  const loggedIn = !!user?.email;

  return (
    <Suspense fallback={LOADING_FALLBACK}>
      <Switch>
        <CommonLayout
          exact
          path={ROUTES.dashboard}
          component={Dashboard}
          hasAccess={loggedIn}
        />
        <CommonLayout
          exact
          path={ROUTES.upload}
          component={Upload}
          hasAccess={loggedIn}
          toolLink={TOOLS_LINKS.upload}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.media}
          component={Media}
          hasAccess={loggedIn}
          toolLink={TOOLS_LINKS.media}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.campaigns}
          component={Campaigns}
          hasAccess={loggedIn}
          toolLink={TOOLS_LINKS.campaigns}
        />
        <CommonLayout
          exact
          path={ROUTES.campaignCreate}
          component={CampaignCreate}
          hasAccess={loggedIn}
          toolLink={TOOLS_LINKS.campaignCreate}
        />
        <CommonLayout
          exact
          path={`${ROUTES.campaigns}/:id`}
          hasAccess={loggedIn}
          toolLink={TOOLS_LINKS.campaignEdit}
          component={CampaignEdit}
        />
        <CommonLayout
          exact
          fullWidth
          path={ROUTES.slides}
          hasAccess={loggedIn}
          toolLink={TOOLS_LINKS.slides}
          component={SlidesBuilder}
        />
        <CommonLayout
          header="Page not found"
          component={NotFound}
          hasAccess={loggedIn}
        />
      </Switch>
    </Suspense>
  );
}

Routes.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
  }),
};

export default memo(Routes);
