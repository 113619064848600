import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.navbarHeight + 40,
    paddingBottom: theme.footerHeight,
    height: '100%',
    overflow: 'hidden',
  },
}));
