/**
 * Config storage class.
 * to be used as singleton to provide global config through app
 */
class Config {
  data = {};

  loaded = false;

  load(configObject) {
    Object.entries(configObject).forEach(([key, val]) => {
      this.data[key] = val;
    });
    this.loaded = true;
  }

  get(key) {
    return this.data[key];
  }
}

export const config = new Config();
