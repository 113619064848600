import React from 'react';
import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom';

import App from 'components/app/App';

import 'index.scss';
import './i18n';
import Providers from './components/app/Providers';

const container = document.getElementById('root');
ReactDOM.render(
  <RecoilRoot>
    <Providers>
      <App />
    </Providers>
  </RecoilRoot>,
  container,
);
