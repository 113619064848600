import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { NAVBAR_Z_INDEX } from 'common/theme';
import './index.scss';

function DotsLoader({ backdrop }) {
  useEffect(() => {
    document.body.style.pointerEvents = backdrop ? 'none' : 'auto';
    return () => {
      document.body.style.pointerEvents = 'auto';
    };
  }, [backdrop]);

  return (
    <>
      {backdrop && (
        <div style={{ zIndex: NAVBAR_Z_INDEX + 1 }} className="dots-backdrop" />
      )}
      <span style={{ zIndex: NAVBAR_Z_INDEX + 2 }} className="dots-loader" />
    </>
  );
}

DotsLoader.propTypes = {
  backdrop: PropTypes.bool,
};
DotsLoader.defaultProps = {
  backdrop: false,
};

export default memo(DotsLoader);
